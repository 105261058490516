import { template as template_03c515357b2e4ec59e834b3808f94b0e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_03c515357b2e4ec59e834b3808f94b0e(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
