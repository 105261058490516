import { template as template_9befb48810e74d20b71a6587552f3e1e } from "@ember/template-compiler";
const SidebarSectionMessage = template_9befb48810e74d20b71a6587552f3e1e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
