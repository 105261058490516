import { template as template_59a7e8a65c114960bf56428120ecf251 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_59a7e8a65c114960bf56428120ecf251(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
